.idea-item-text {
    white-space: pre-line;
}

.right-content-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.tag {
    color: grey;
}

.list-item-container {
    padding-top: 20px;
    padding-bottom: 20px;
}
