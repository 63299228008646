.toolbar-container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
}

.toolbar-content-container {
    position: relative;
    height: 100%;
    overflow-y: hidden;
}

.button {
    position: relative;
    transition: transform 0.3s;
    display: inline-block;
    z-index: 2;
}

.button.rotate90 {
    transform: rotate(90deg);
}

.button.rotate180 {
    transform: rotate(180deg);
}

.toolbar-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: -200px;
    left: 0;
    right: 0;
    z-index: 1;
}

.visible {
    bottom: 0px;
}
