.login-form {
    max-width: 300px;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}
.auth-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.auth-form-error-text {
    color: red;
}
