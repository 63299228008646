@import '~antd/dist/antd.css';

.content {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 927px;
    min-width: 360px;
    font-family: monaco, monospace;
}

.add-idea-container {
    display: flex;
    flex-direction: column;
}

.submit-button {
    align-self: flex-end;
    margin: 5px;
}

.list-item-container {
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
}

/* antd css overrides */
/* toolbar margins were updating at small widths and hiding the toolbar buttons */
.ant-list-item-extra {
    margin: 0px !important;
    margin-left: 24px !important;
}
