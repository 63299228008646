.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 70);
    padding-top: 20px;
}

.header-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-title {
    color: white;
    font-size: xx-large;
    font-family: courier, monospace;
}

.header-icon {
    padding-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
}

.header-button-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
}
